<template>
  <div class="profile-wrapper emb-card pa-4">
    <h4>Cart info</h4>
    <div v-if="cart != null && cart.products.length > 0">
      <div v-for="(product, key) in cart.products" :key="key">
        <div v-html="displayProduct(product)"></div>
      </div>
      <div class="mt-6">
        <div v-if="!is_processing">
          <v-btn @click="create_order()">Create Order </v-btn>
        </div>
        <div v-else>
          <v-progress-circular indeterminate></v-progress-circular>
        </div>
      </div>
      <div class="mt-6">
        <v-btn @click="delete_cart()">Delete cart </v-btn>
      </div>
    </div>
    <div v-else>The cart is empty</div>
    <emb-delete-confirmation
      ref="orderConfirmationDialog"
      message="Confirm the order creation"
      @onConfirm="onOrderFromCart"
    >
    </emb-delete-confirmation>
    <emb-delete-confirmation
      ref="deleteCartConfirmationDialog"
      message="Are you sure you want to delete the user cart?"
      @onConfirm="onDeleteProductFromCart"
    >
    </emb-delete-confirmation>
  </div>
</template>

<script>
import { getFirestore, doc, getDoc, deleteDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["general_info", "getCartTotalPriceWithCredit"]),
  },
  data() {
    return {
      customer_id: "",
      cart: null,
      is_processing: false,
    };
  },
  beforeMount() {
    this.customer_id = this.$route.params.id;
    this.load_cart();
  },
  methods: {
    load_cart() {
      const cartRef = doc(getFirestore(), "cart", this.customer_id);
      getDoc(cartRef).then((docSnapshot) => {
        this.cart = docSnapshot.data();
      });
    },
    onDeleteProductFromCart() {
      this.$refs.deleteCartConfirmationDialog.close();
      const cartRef = doc(getFirestore(), "cart", this.customer_id);
      deleteDoc(cartRef).then(() => {
        this.load_cart();
      });
    },
    delete_cart() {
      this.$refs.deleteCartConfirmationDialog.openDialog();
    },
    get_numbers(grid) {
      var result = [];
      for (var current_grid of grid) {
        var grid_str = current_grid.pool[0].numbers
          .toString()
          .replaceAll(",", "-");
        grid_str +=
          " (" +
          current_grid.pool[1].numbers.toString().replaceAll(",", "-") +
          ")";
        result.push(grid_str);
      }
      return result;
    },
    displayProduct(product) {
      var result = "";
      var type_str = {
        megamillions: { name: "Mega Millions", multiplier: "megaplier" },
        powerball: { name: "Powerball", multiplier: "powerplay" },
      };
      if (product.ticket.quickPicks != undefined) {
        result =
          product.ticket.quickPicks.toString() +
          " QP of " +
          type_str[product.ticket.type_name].name;
      } else {
        result =
          product.ticket.grids.length.toString() +
          " grids of " +
          type_str[product.ticket.type_name].name;
      }

      if (product.ticket.multiplier) {
        result += " with " + type_str[product.ticket.type_name].multiplier;
      }
      if (product.ticket.multiplay > 1) {
        result += " (multiplay of " + product.ticket.multiplay + ")";
      }

      if (product.ticket.grids != undefined) {
        result +=
          "<br>" +
          this.get_numbers(product.ticket.grids)
            .toString()
            .replaceAll(",", "<br>");
      }
      return result;
    },
    create_order() {
      this.$refs.orderConfirmationDialog.openDialog();
    },
    async onOrderFromCart() {
      this.$refs.orderConfirmationDialog.close();
      var customer_info = await this.$store.dispatch(
        "get_user_info_cached",
        this.customer_id
      );
      this.is_processing = true;
      const createInteracPaymentForUser = httpsCallable(
        getFunctions(),
        "createInteracPaymentForUser"
      );
      createInteracPaymentForUser({
        cart: this.cart.products,
        currency_rate: this.general_info.currency["CAD"],
        customer_id: this.customer_id,
        customer_info: customer_info,
        use_credit: true,
        empty_cart: true,
      })
        .then(() => {
          setTimeout(() => {
            this.load_cart();
            this.is_processing = false;
          }, 1000);
        })
        .catch((error) => {
          console.log("Error: ", error);
          this.is_processing = false;
        });
    },
  },
};
</script>